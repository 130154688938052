import React, { useState, useEffect } from 'react';
import { getEventDataById } from 'services/customer/event';
import { globalCurrentEventId } from 'utils/global';

interface ExhibitorDelegateData {
  applicant: any;
  event: any;
  information: any;
}

const PageExhibitorDelegate = () => {

  const globalEventId = globalCurrentEventId();

  // Sample data to represent the state (can be fetched from an API)
  const [exhibitorDelegateData, setExhibitorDelegateData] = useState<ExhibitorDelegateData>({
    applicant: {},
    event: {},
    information: {}
  });

  // Function to handle the purchase of extra passes
  const handleBuyExtraPasses = () => {
    window.open(exhibitorDelegateData.event.link_exhibitor_delegate, '_blank');


  };

  // Function to handle registration redirection
  const handleRegisterDelegate = () => {
    window.open(exhibitorDelegateData.event.link_exhibitor_delegate, '_blank');
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getEventDataById(globalEventId, 'exhibitor-delegates');
      if (res) {
        setExhibitorDelegateData(res)
      }
    }
    fetchData();

  }, [])
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Exhibitor Delegate Registration </h1>

      {/* Exhibitor Delegate Information */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-2">Exhibitor Delegate Information</h2>
        {exhibitorDelegateData?.applicant?.promocode ? (
          <>
            <p>
              You have <span className="font-bold">{exhibitorDelegateData?.applicant?.promocode.delegate_quantity}</span> passes allocated. Please register your exhibitor delegate as per instructions below
            </p>
            <p>
              Promo Code: <span className="font-bold">{exhibitorDelegateData?.applicant?.promocode.delegate}</span>
            </p>
          </>
        ) : (
          <p>
            No promo code yet!
          </p>
        )}

      </div>

      {/* Register Delegate Link */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-2">Register Your Delegate Passes</h2>
        <p>
          To register your delegates, please follow the link below:
        </p>
        <button
          onClick={handleRegisterDelegate}
          className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
        >
          Register Delegate Passes
        </button>
      </div>

      {/* Buy Extra Delegate Passes */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-2">Buy Extra Delegate Passes</h2>
        <p>
          You can buy extra Delegate Passes<span className="font-bold"></span>
        </p>
        <button
          onClick={handleBuyExtraPasses}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
        >
          Buy Extra Passes
        </button>
      </div>
      {/* Action button */}
      <div className="bg-white p-6 mb-6">
        <a
          href="/dashboard"
          className="inline-block mt-4 bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium px-6 py-3 rounded-lg shadow-md hover:from-blue-600 hover:to-blue-700 hover:shadow-lg transition duration-300 ease-in-out"
        >
          Back to Dashboard
        </a>
      </div>
    </div>
  );
};

export default PageExhibitorDelegate;
