import React from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { ToastContainer } from "react-toastify";
import { useAuth } from "context/AuthContext";
import PrivateRoute from "./PrivateRoute";

import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSettings from "containers/PageSetting/PageSetting";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageVerify from "containers/PageAuthVerify/PageVerify";

import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import PageCollection from "containers/PageCollection";
import PageSearch from "containers/PageSearch";
import PageHome2 from "containers/PageHome/PageHome2";
import PageHome3 from "containers/PageHome/PageHome3";
import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
import ProductDetailPage2 from "containers/ProductDetailPage/ProductDetailPage2";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountBilling from "containers/AccountPage/AccountBilling";
import AccountOrder from "containers/AccountPage/AccountOrder";
import CartPage from "containers/ProductDetailPage/CartPage";
import CheckoutPage from "containers/PageCheckout/CheckoutPage";
import PageCollection2 from "containers/PageCollection2";
import PageCoaches from "containers/PageCoaches/PageCoaches";
import PagePlayers from "containers/PagePlayers/PagePlayers";
import PageFAQ from "containers/PageFAQ/PageFAQ";
import PageCalenda from "containers/PageCalenda/PageCalenda";
import PageDashboard from "containers/PageDashboard/PageDashboard";
import PageAvailability from "containers/PageAvailability/PageAvailability";
import PageExercise from "containers/PageExercise/PageExercise";
import PageGroup from "containers/PageExercise/PageGroup";
import PagePlayerCoaches from "containers/PlayerPages/Coaches";
// import PageMembers from "containers/PageMembers/PageMembers";
import PageMembers from "containers/PlayerPages/Players";
import PagePlayerDashboard from "containers/PlayerPages/Dashboard";
import PagePlayerCalenda from "containers/PlayerPages/Calendar";
import PageEvents from "containers/PageEvents/PageEvents";
import PageEventDetail from "containers/PageEventDetail";
import PageBooking from "containers/PageBooking";
import PageInvoice from "containers/PageInvoice";
import PageExhibitors from "containers/PageExhibitors";
import PageTermsConditioins from "containers/PageTermsConditions";
import PageBlank from "containers/PageBlank/PageBlank";
import PageExhibitorInformation from "containers/PageExhibitorInformation";
import PageBoothStaff from "containers/PageBoothStaff";
import PageExhibitorDelegate from "containers/PageExhibitorDelegates";
import PageOrderFurniture from "containers/PageOrderFurniture";
import PageOrderBasket from "containers/PageOrderBasket";
import PageOrderHistory from "containers/PageOrderHistory";
import PageOrderElectric from "containers/PageOrderElectric";
import PageOrderAV from "containers/PageOrderAV";
import PageAdditionalServices from "containers/PageAdditionalServices";

export const pages: Page[] = [
  { path: "/", component: PageHome },

  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/verify-email/:token", component: PageVerify },

  //////////////////////////////////////////////
  { path: "/events", component: PageEvents },
  // { path: "/floorplans/:id", component: PageEventDetail },//events
  { path: "/booking", component: PageBooking },
  { path: "/invoice", component: PageInvoice },
  { path: "/exhibitors/:id", component: PageExhibitors },
  { path: "/terms-conditions/:id", component: PageTermsConditioins },//events

  { path: "/account", component: AccountPage },
  { path: "/dashboard", component: PageDashboard },
  { path: "/exhibitor-information", component: PageExhibitorInformation },
  { path: "/register-booth-staff", component: PageBoothStaff },
  { path: "/register-exhibitor-delegates", component: PageExhibitorDelegate },
  { path: "/order-furniture", component: PageOrderFurniture },
  { path: "/order-electrics", component: PageOrderElectric },
  { path: "/order-audio-visual", component: PageOrderAV },
  { path: "/additional-services", component: PageAdditionalServices },
  { path: "/order-basket", component: PageOrderBasket },
  { path: "/order-review", component: PageOrderHistory },
  { path: "/checkout", component: CheckoutPage },
  { path: "/account-my-order", component: AccountOrder },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-change-password", component: AccountPass },
  { path: "/account-billing", component: AccountBilling },
  { path: "/settings", component: PageSettings },


  // { path: "/contact", component: PageContact },
  // { path: "/about", component: PageAbout },
  // { path: "/faq", component: PageFAQ },
  // { path: "/subscription", component: PageSubcription },

  // { path: "/home2", component: PageHome2 },
  // { path: "/home3", component: PageHome3 },
  // //
  // { path: "/home-header-2", component: PageHome },
  // { path: "/product-detail", component: ProductDetailPage },
  // { path: "/product-detail-2", component: ProductDetailPage2 },
  // //
  // { path: "/page-collection-2", component: PageCollection2 },
  // { path: "/page-collection", component: PageCollection },
  // { path: "/page-search", component: PageSearch },

  //
  //
  // { path: "/settings", component: PageSettings },
  //

  // { path: "/blog", component: BlogPage },
  // { path: "/blog-single", component: BlogSingle },

  // { path: "/coaches", component: PageCoaches },
  // { path: "/players", component: PagePlayers },
  // { path: "/calendar", component: PageCalenda },
  // { path: "/availability", component: PageAvailability },
  // { path: "/exercises", component: PageExercise },
  // { path: "/group", component: PageGroup },
  // { path: "/members", component: PageMembers },
  // { path: "/player-coaches", component: PagePlayerCoaches },
  // { path: "/player-dashboard", component: PagePlayerDashboard },
  // { path: "/player-calendar", component: PagePlayerCalenda },



];

const MyRoutes = () => {
  const { isAuthenticated } = useAuth();

  return (
    <BrowserRouter>
      {/* react-hot-toast */}
      <Toaster />
      {/* react-toastify notification */}
      <ToastContainer />
      <ScrollToTop />
      <SiteHeader />
      <Routes>
        {pages.map(({ component: Component, path }, index) => {

          return <Route key={index} element={<Component />} path={path} />;
        })}
        <Route path="/floorplans/:id" element={<PageEventDetail />} />
        <Route element={<Page404 />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
