import axiosInstance from 'services/interceptor';

export const getOrderInvoice = async (orderId: any): Promise<any> => {
    try {
        const response = await axiosInstance.get(`/customer/invoice`, {
            params: {
                order_id: orderId,
            },
            responseType: 'blob', 
        });
        return response.data;
    } catch (error: any) {
        throw error;
    }
}