
import axiosInstance from 'services/interceptor';

export const checkValidPayment = async (data: any): Promise<any> => {
  try {
    const response = await axiosInstance.post(`/guest/payment/is-valid-payment`, {
      ...data,
      amount: data.amount, // amount in cents
      currency: data.currency,
    });
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error.message;
  }
}

export const getPaymentIntent = async (data: any): Promise<any> => {
  try {
    const response = await axiosInstance.post(`/guest/payment/create-payment-intent`, {
      ...data,
      amount: data.amount, // amount in cents
      currency: data.currency,
    });
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error.message;
  }
}
  
export const confirmPayment = async (data: any) => {
  try {
    const response = await axiosInstance.post(`/guest/payment/confirm-payment`, {
      ...data,
      client_secret: data.clientSecret, 
    });
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error.message;
  }
}


export const createPaymentPaypal = async (data: any): Promise<any> => {
  try {
    const response = await axiosInstance.post(`/guest/payment/create-payment-paypal`, {
      ...data,
      amount: data.amount, // amount in cents
      currency: data.currency,
    });
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error.message;
  }
}

export const confirmPaymentPaypal = async (data: any) => {
  try {
    const response = await axiosInstance.post(`/guest/payment/confirm-payment-paypal`, {
      ...data,
      orderId: data.orderId, 
    });
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error.message;
  }
}
