// src/components/StripePaymentPage.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { getSripePaymentIntent } from 'services/customer/payment';
const REACT_APP_STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || 'your stripe api key'
const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);

interface StripeProps {
    amount: number;
    currency: string;
    billing_name: string;
    billing_email: string;
    meta: any;
    onSuccess: (response: any) => void;
    onError: (error: any) => void;
}
// Styling for the Card Element with Tailwind CSS
const cardStyle: any = {
    iconStyle: 'solid',
    style: {
        base: {
            color: '#32325d',
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#a0aec0',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    },
};

// Form component for Stripe Payment
const CheckoutForm: React.FC<any> = ({ data, onSuccess, onError }) => {

    // const navigate = useNavigate();

    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [clientSecret, setClientSecret] = useState<string | null>(null);

    // Fetch client secret from the backend when component mounts

    useEffect(() => {
        const amount = data.amount || 0;
        const currency = data.currency;

        getSripePaymentIntent({
            amount: amount, // amount in cents
            currency: currency, //'gbp',
            meta: data?.meta
        })
            .then((response: any) => {
                setClientSecret(response.clientSecret);
            })
            .catch((error: any) => {
                setError(`${error.message}`);
            });
    }, [data?.meta]);

    // Handle form submission
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!stripe || !elements || !clientSecret) {
            return;
        }

        setLoading(true);
        setError(null);

        const cardElement = elements.getElement(CardElement);

        if (!cardElement) return;

        // Confirm the payment with the client secret from the backend
        const { error, paymentIntent }: any = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    name: `${data.billing_name}`,
                    email: data.billing_email,
                },
            },
        });

        // Check for errors and prepare the data to send to the server
        if (error) {
            setError(error.message);
            setLoading(false);

        } else if (paymentIntent && paymentIntent.status === 'succeeded') {

            setSuccessMessage('Payment successful! Thank you for your purchase.');
            setLoading(false);

            await onSuccess({
                success: true,
                paymentIntentId: paymentIntent.id,
                amount: paymentIntent.amount,
                currency: paymentIntent.currency,
                clientSecret: clientSecret
            });

        } else {

            setError('Something went wrong');
            setLoading(false);
            await onError();
        }

    };

    // Handle card input changes
    const handleCardChange = (event: any) => {
        setError(event.error ? event.error.message : '');
    };

    return (
        <form onSubmit={handleSubmit} className="w-100 mx-auto p-8 bg-white rounded-lg shadow-lg space-y-6">
            {/* <h2 className="text-2xl font-semibold text-gray-800 mb-4">Purchase by Stripe</h2> */}

            {/* Card Element for Stripe */}
            <div className="border p-4 rounded-md shadow-sm">
                <CardElement options={cardStyle} onChange={handleCardChange} />
            </div>

            {/* Display Errors */}
            {error && <div className="text-red-500 text-xl mt-2">{error}</div>}

            {/* Payment Button */}
            <button
                type="submit"
                disabled={!stripe || loading || !clientSecret}
                className={`w-full mt-4 px-4 py-2 font-semibold text-white bg-blue-500 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 ${loading ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
            >
                {loading ? 'Processing...' : 'Pay Now'}
            </button>

            {/* Success Message */}
            {successMessage && <div className="text-green-500 text-sm mt-2">{successMessage}</div>}
        </form>
    );
};

// Stripe Elements Wrapper
const StripePayment: React.FC<StripeProps> = ({
    amount,
    currency,
    billing_name,
    billing_email,
    meta,
    onSuccess,
    onError
}) => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm data={
                {
                    amount,
                    currency,
                    billing_name,
                    billing_email,
                    meta
                }
            }
                onSuccess={onSuccess}
                onError={onError}
            />
        </Elements>
    );
};

export default StripePayment;
