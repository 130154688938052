import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface Event {
    id: number;
    title: string;
    full_name: string;
    map_id: number | null;
    image: string | null;
    start_date: string;
    end_date: string;
    time: string;
    location: string;
    contact_name: string;
    contact_email: string;
    link: string | null;
    terms_and_conditions: string | null;
    status: string | null;
    deleted: string;
    tax: string;
    currency: string;
    createdAt: string;
    updatedAt: string;
}

interface EventSelectCardProps {
    events: Event[];
    onChange?: (event_id: number) => void
}

const EventSelectCard: React.FC<EventSelectCardProps> = ({ events, onChange = () => {} }) => {
    const navigate = useNavigate();
    const [selectedEventId, setSelectedEventId] = useState<number | null>(() => {
        const storedEventId = localStorage.getItem("selectedEventId");
        return storedEventId ? parseInt(storedEventId, 10) : null;
    });

    useEffect(() => {
        if (selectedEventId !== null) {
            localStorage.setItem("selectedEventId", selectedEventId.toString());
        } else {
            localStorage.removeItem("selectedEventId");
        }
    }, [selectedEventId]);

    const handleSelectEvent = (eventId: number) => {
        setSelectedEventId(eventId);
        navigate(`/dashboard?event=${eventId}`);
        onChange(eventId);
    };

    const selectedEvent = events?.find((event) => event.id === selectedEventId);

    return (
        <div className="w-full">
            {!selectedEvent ? (
                <div className="overflow-x-auto whitespace-nowrap py-4">
                    <div className="flex gap-6">
                        {events?.map((event) => (
                            <div
                                key={event.id}
                                className="min-w-[350px] bg-white shadow-md rounded-lg p-6 border border-gray-200 flex-shrink-0"
                            >
                                <div className="flex flex-col items-center text-center">
                                    {/* Event Image */}
                                    {event.image ? (
                                        <img
                                            src={`${process.env.REACT_APP_BACKEND_URL}/${event.image}`}
                                            alt={event.title}
                                            className="w-32 h-32 object-contain rounded-md"
                                        />
                                    ) : (
                                        <div className="w-32 h-32 flex items-center justify-center bg-gray-300 rounded-md text-gray-700">
                                            No Logo
                                        </div>
                                    )}

                                    {/* Event Details */}
                                    <h2 className="text-xl font-bold text-gray-800 mt-4">
                                        {event.title || "Unknown Event"}
                                    </h2>
                                    <p className="text-sm text-gray-500">
                                        {event.full_name || "No full name provided"}
                                    </p>
                                    <p className="mt-2 text-sm text-gray-600">
                                        <strong>Location:</strong> {event.location || "No location"}
                                    </p>
                                    <p className="mt-1 text-sm text-gray-600">
                                        <strong>Start:</strong> {event.start_date} |{" "}
                                        <strong>End:</strong> {event.end_date}
                                    </p>
                                </div>

                                {/* Divider */}
                                <hr className="my-4 border-gray-300" />

                                {/* Contact Info */}
                                <div className="grid grid-cols-1 gap-4">
                                    <div>
                                        <h3 className="text-sm font-medium text-gray-600">
                                            Contact Name
                                        </h3>
                                        <p className="text-gray-800">{event.contact_name || "N/A"}</p>
                                    </div>
                                    <div>
                                        <h3 className="text-sm font-medium text-gray-600">Email</h3>
                                        <p className="text-gray-800">
                                            {event.contact_email || "N/A"}
                                        </p>
                                    </div>
                                </div>

                                {/* Select Button */}
                                <button
                                    onClick={() => handleSelectEvent(event.id)}
                                    className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 w-full"
                                >
                                    Manage This Event
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="w-full bg-white shadow-md rounded-lg p-6 border border-gray-200">
                    <div className="flex items-start gap-x-4">
                        {/* Logo or Placeholder */}
                        {selectedEvent.image ? (
                            <img
                                src={`${process.env.REACT_APP_BACKEND_URL}/${selectedEvent.image}`}
                                alt={selectedEvent.title}
                                className="w-32 h-32 object-contain rounded-md"
                            />
                        ) : (
                            <div className="w-16 h-16 flex items-center justify-center bg-gray-300 rounded-md text-gray-700">
                                No Logo
                            </div>
                        )}

                        {/* Event Details */}
                        <div>
                            <h2 className="text-2xl font-bold text-gray-800">
                                {selectedEvent.title || "Unknown Event"}
                            </h2>
                            <p className="text-sm text-gray-500">
                                {selectedEvent.full_name || "No full name provided"}
                            </p>
                            <p className="mt-2 text-sm text-gray-600">
                                <strong>Location:</strong>{" "}
                                {selectedEvent.location || "No location specified"}
                            </p>
                            <p className="mt-1 text-sm text-gray-600">
                                <strong>Start Date:</strong> {selectedEvent.start_date} |{" "}
                                <strong>End Date:</strong> {selectedEvent.end_date}
                            </p>
                        </div>
                    </div>

                    {/* Divider */}
                    <hr className="my-4 border-gray-300" />

                    {/* Contact Info */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <h3 className="text-sm font-medium text-gray-600">
                                Contact Name
                            </h3>
                            <p className="text-gray-800">
                                {selectedEvent.contact_name || "N/A"}
                            </p>
                        </div>
                        <div>
                            <h3 className="text-sm font-medium text-gray-600">Email</h3>
                            <p className="text-gray-800">
                                {selectedEvent.contact_email || "N/A"}
                            </p>
                        </div>
                    </div>

                    {/* Back Button */}
                    <button
                        onClick={() => {
                            setSelectedEventId(null);
                            onChange(0);
                        }}
                        className="mt-4 px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700"
                    >
                        Your Other Events
                    </button>
                </div>
            )}
        </div>
    );
};

export default EventSelectCard;
