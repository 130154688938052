import { PayPalScriptProvider, PayPalButtons, PayPalButtonsComponentProps, FUNDING } from "@paypal/react-paypal-js";
import Label from "components/Label/Label";
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import React, { FC, useState, useCallback } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Radio from "shared/Radio/Radio";
import StripePayment from "components/Payment/Stripe";
// import { createPaymentPaypal, confirmPaymentPaypal, getPaymentIntent, confirmPayment } from "../../services/payments";
import { createPayPalPayment, confirmSripePayment, confirmPaypalPayment, createBankPayment } from "services/customer/payment";

interface Props {
  isActive: boolean;
  data: any;
  onCloseActive: () => void;
  onOpenActive: () => void;
}

const PaymentMethod: FC<Props> = ({
  isActive,
  data,
  onCloseActive,
  onOpenActive,
}) => {
  const { amount, currency, meta, orderId } = data;

  const navigate = useNavigate();
  const [methodActive, setMethodActive] = useState<"Credit-Card" | "Internet-banking" | "Wallet" | "Paypal">("Credit-Card");
  const [bankInfo, setBankInfo] = useState({
    name: '',
    account_number: '',
    sort_code: '',
    iban: '',
    swift_code: ''
  })
  const onChangeInput = (e: any) => {
    setBankInfo({
      ...bankInfo,
      [e.target.name]: e.target.value
    })
  }
  const onSubmitBankInfo = async () => {
    console.log('bank info', bankInfo);
    const order = await createBankPayment({
      amount,
      currency,
      meta
    })
    if (order) {
      navigate(`/invoice/?type=order&order_id=${orderId}`);
    }
  }
  const onSuccessStripe = async (data: any) => {
    const res = await confirmSripePayment(data);
    if (res.order) {
      // navigate('/order-review', { state: { orderId: res.order.id } });
      navigate(`/invoice/?type=order&order_id=${orderId}`);
    } else {
      console.error('Payment confirmation failed');
    }

  }

  const createOrder = useCallback(
    async (orderData: any, actions: any) => {
      console.log(data, orderData, actions);

      try {
        const response = await createPayPalPayment({
          amount: amount, // amount in cents
          currency: currency, //'gbp',
          billing_email: data.primary_email,
          meta: {
            orderId: data.orderId
          }
        });

        if (!response) {
          throw new Error('Failed to create order');
        }

        const order = response.order;

        // Extract the approval URL from the PayPal response
        const approveLink = order?.links.find((link: any) => link.rel === 'approve');
        if (!approveLink) {
          throw new Error('Approval link not found');
        }

        // Redirect the user to the PayPal approval URL
        window.location.href = approveLink.href;

        return response;
      } catch (error) {
        console.error('Error creating order:', error);
        throw error;
      }
    },
    [amount]
  );

  const onApprove: PayPalButtonsComponentProps['onApprove'] = async (data, actions) => {
    // orderId
    const orderId = data.orderID;
    if (actions.order) {
      return actions.order.capture().then(async (details: any) => {
        console.log('Transaction completed by ' + details.payer.name.given_name);
        const res = await confirmPaypalPayment({
          paymentId: orderId,
          details
        });

      });
    } else {
      throw new Error('Order capture failed');
    }
  };

  const renderDebitCredit = () => {

    const active = methodActive === "Credit-Card";

    return (
      <div className="flex items-start space-x-4 sm:space-x-6">
        <Radio
          className="pt-3.5"
          name="payment-method"
          id="Credit-Card"
          defaultChecked={active}
          onChange={(e) => setMethodActive(e as any)}
        />
        <div className="flex-1">
          <label
            htmlFor="Credit-Card"
            className="flex items-center space-x-4 sm:space-x-6"
          >
            <div
              className={`p-2.5 rounded-xl border-2 ${active
                ? "border-slate-600 dark:border-slate-300"
                : "border-gray-200 dark:border-slate-600"
                }`}
            >
              <svg
                className="w-6 h-6 sm:w-7 sm:h-7"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M2 12.6101H19"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19 10.28V17.43C18.97 20.28 18.19 21 15.22 21H5.78003C2.76003 21 2 20.2501 2 17.2701V10.28C2 7.58005 2.63 6.71005 5 6.57005C5.24 6.56005 5.50003 6.55005 5.78003 6.55005H15.22C18.24 6.55005 19 7.30005 19 10.28Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22 6.73V13.72C22 16.42 21.37 17.29 19 17.43V10.28C19 7.3 18.24 6.55 15.22 6.55H5.78003C5.50003 6.55 5.24 6.56 5 6.57C5.03 3.72 5.81003 3 8.78003 3H18.22C21.24 3 22 3.75 22 6.73Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.25 17.8101H6.96997"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.10986 17.8101H12.5499"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <p className="font-medium">Debit / Credit Card</p>
          </label>

          <div
            className={`mt-6 mb-4 space-y-3 sm:space-y-5 ${active ? "block" : "hidden"
              }`}
          >
            <StripePayment
              amount={data?.amount || 100}
              currency={'gbp'}
              billing_name={"billing name"}
              billing_email={"stripe@email.com"}
              meta={{
                orderId: data?.orderId
              }}
              onSuccess={onSuccessStripe}
              onError={() => { }}
            />

            {/* <div className="max-w-lg">
              <Label className="text-sm">Card number</Label>
              <Input className="mt-1.5" type={"text"} />
            </div>
            <div className="max-w-lg">
              <Label className="text-sm">Name on Card</Label>
              <Input className="mt-1.5" />
            </div>
            <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-3">
              <div className="sm:w-2/3">
                <Label className="text-sm">Expiration date (MM/YY)</Label>
                <Input className="mt-1.5" placeholder="MM/YY" />
              </div>
              <div className="flex-1">
                <Label className="text-sm">CVC</Label>
                <Input className="mt-1.5" placeholder="CVC" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  const renderInterNetBanking = () => {
    const active = methodActive === "Internet-banking";
    return (
      <div className="flex items-start space-x-4 sm:space-x-6">
        <Radio
          className="pt-3.5"
          name="payment-method"
          id="Internet-banking"
          defaultChecked={active}
          onChange={(e) => setMethodActive(e as any)}
        />
        <div className="flex-1">
          <label
            htmlFor="Internet-banking"
            className="flex items-center space-x-4 sm:space-x-6"
          >
            <div
              className={`p-2.5 rounded-xl border-2 ${active
                ? "border-slate-600 dark:border-slate-300"
                : "border-gray-200 dark:border-slate-600"
                }`}
            >
              <svg
                className="w-6 h-6 sm:w-7 sm:h-7"
                viewBox="0 0 24 24"
                fill="none"
              >
                {/* SVG Path for Internet Banking Icon */}
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                {/* Additional SVG Paths */}
                <path
                  d="M7.99998 3H8.99998C7.04998 8.84 7.04998 15.16 8.99998 21H7.99998"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15 3C16.95 8.84 16.95 15.16 15 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 16V15C8.84 16.95 15.16 16.95 21 15V16"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 9.0001C8.84 7.0501 15.16 7.0501 21 9.0001"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <p className="font-medium">Bank Transfer</p>
          </label>

          <div
            className={`mt-6 mb-4 space-y-3 sm:space-y-5 ${active ? "block" : "hidden"}`}
          >
            <div className="max-w-lg space-y-4 p-4 rounded-lg shadow-md">
              <div>
                <h2 className="text-lg font-semibold text-gray-800">Notes:</h2>
                <ul className="text-sm text-gray-600 list-disc list-inside">
                  <li>Payment required before entry allowed.</li>
                  <li>Payment terms:</li>
                </ul>
                <div className="pl-6 text-sm text-gray-600">
                  <p>Full payment is due within 14 days of invoice date.</p>
                  <p>Full payment required prior to entry.</p>
                </div>
                <ul className="text-sm text-gray-600 list-disc list-inside">
                  <li>Information for bank transfer also detailed on the invoice.</li>
                </ul>
              </div>

              {/* <div className="pt-5">
                <h2 className="text-lg font-semibold text-gray-800">{`Bank Transfer (${'GBP'}):`}</h2>
                <div className="text-sm text-gray-600">
                  <p><strong>Account number:</strong> 2186 7793 9446</p>
                  <p><strong>ACH routing number:</strong> 101019644</p>
                  <p><strong>Wire routing number:</strong> 101019644</p>
                  <p><strong>Bank/Payment institution:</strong> Lead Bank</p>
                  <p><strong>Bank Account Name:</strong> Torch Marketing Co Ltd</p>
                </div>
              </div> */}

              <div className="pt-5">
                <div className="text-sm text-gray-600">
                  Checkout via Bank Transfer, you will be forwarded an invoice for payment. Payment needs to be received prior to the order delivery.
                </div>
              </div>
            </div>

            {/* <div className="max-w-lg">
              <Label className="text-sm">Bank Name</Label>
              <Input className="mt-1.5" type="text" placeholder="Example Bank Name" name="name" onChange={onChangeInput} />
            </div>
            <div className="max-w-lg">
              <Label className="text-sm">Account Number</Label>
              <Input className="mt-1.5" placeholder="555 888 777" name="account_number" onChange={onChangeInput} />
            </div>
            <div className="max-w-lg">
              <Label className="text-sm">Sort Code</Label>
              <Input className="mt-1.5" placeholder="999" name="sort_code" onChange={onChangeInput} />
            </div>
            <div className="max-w-lg">
              <Label className="text-sm">IBAN</Label>
              <Input className="mt-1.5" placeholder="IBAN" name="iban" onChange={onChangeInput} />
            </div>
            <div className="max-w-lg">
              <Label className="text-sm">BIC/Swift Code</Label>
              <Input className="mt-1.5" placeholder="BIC/Swift" name="swift" onChange={onChangeInput} />
            </div> */}
            <ButtonPrimary
              className="w-full  mt-3 max-w-[240px]"
              onClick={onSubmitBankInfo}
            >
              Checkout now
            </ButtonPrimary>
          </div>

        </div>
      </div >
    );

  };

  const renderWallet = () => {
    const active = methodActive === "Wallet";
    return (
      <div className="flex items-start space-x-4 sm:space-x-6">
        <Radio
          className="pt-3.5"
          name="payment-method"
          id="Wallet"
          defaultChecked={active}
          onChange={(e) => setMethodActive(e as any)}
        />
        <div className="flex-1">
          <label
            htmlFor="Wallet"
            className="flex items-center space-x-4 sm:space-x-6 "
          >
            <div
              className={`p-2.5 rounded-xl border-2 ${active
                ? "border-slate-600 dark:border-slate-300"
                : "border-gray-200 dark:border-slate-600"
                }`}
            >
              <svg
                className="w-6 h-6 sm:w-7 sm:h-7"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H6.26C4.19 22 2.5 20.31 2.5 18.24V11.51C2.5 9.44001 4.19 7.75 6.26 7.75H17.74C19.81 7.75 21.5 9.44001 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.5 12.4101V7.8401C2.5 6.6501 3.23 5.59006 4.34 5.17006L12.28 2.17006C13.52 1.70006 14.85 2.62009 14.85 3.95009V7.75008"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22.5588 13.9702V16.0302C22.5588 16.5802 22.1188 17.0302 21.5588 17.0502H19.5988C18.5188 17.0502 17.5288 16.2602 17.4388 15.1802C17.3788 14.5502 17.6188 13.9602 18.0388 13.5502C18.4088 13.1702 18.9188 12.9502 19.4788 12.9502H21.5588C22.1188 12.9702 22.5588 13.4202 22.5588 13.9702Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7 12H14"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <p className="font-medium">Google / Apple Wallet</p>
          </label>
          <div className={`mt-6 mb-4 space-y-6 ${active ? "block" : "hidden"}`}>
            <div className="text-sm prose dark:prose-invert">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque
                dolore quod quas fugit perspiciatis architecto, temporibus quos
                ducimus libero explicabo?
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPaypal = () => {

    const initialOptions = {
      clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID || "paypal-client-id",
      currency: "GBP",
      intent: "capture",
    };


    const active = methodActive === "Paypal";
    return (
      <div className="flex items-start space-x-4 sm:space-x-6">
        <Radio
          className="pt-3.5"
          name="payment-method"
          id="Paypal"
          defaultChecked={active}
          onChange={(e) => setMethodActive(e as any)}
        />
        <div className="flex-1">
          <label
            htmlFor="Paypal"
            className="flex items-center space-x-4 sm:space-x-6 "
          >
            <div
              className={`p-2.5 rounded-xl border-2 ${active
                ? "border-slate-600 dark:border-slate-300"
                : "border-gray-200 dark:border-slate-600"
                }`}
            >
              <svg
                className="w-6 h-6 sm:w-7 sm:h-7"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H6.26C4.19 22 2.5 20.31 2.5 18.24V11.51C2.5 9.44001 4.19 7.75 6.26 7.75H17.74C19.81 7.75 21.5 9.44001 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.5 12.4101V7.8401C2.5 6.6501 3.23 5.59006 4.34 5.17006L12.28 2.17006C13.52 1.70006 14.85 2.62009 14.85 3.95009V7.75008"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22.5588 13.9702V16.0302C22.5588 16.5802 22.1188 17.0302 21.5588 17.0502H19.5988C18.5188 17.0502 17.5288 16.2602 17.4388 15.1802C17.3788 14.5502 17.6188 13.9602 18.0388 13.5502C18.4088 13.1702 18.9188 12.9502 19.4788 12.9502H21.5588C22.1188 12.9702 22.5588 13.4202 22.5588 13.9702Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7 12H14"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <p className="font-medium">Paypal</p>
          </label>
          <div className={`mt-6 mb-4 space-y-6 ${active ? "block" : "hidden"}`}>
            <div className="text-sm prose dark:prose-invert">
              <p>
                You can pay by Paypal
              </p>
              <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                  createOrder={createOrder}
                  onApprove={onApprove}
                  fundingSource={FUNDING.PAYPAL} // Show only PayPal button
                  style={{
                    layout: 'vertical',
                    shape: 'rect',
                    label: 'paypal',
                  }}
                />
              </PayPalScriptProvider>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPaymentMethod = () => {

    return (
      <div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
        <div className="p-6 flex flex-col sm:flex-row items-start">
          <span className="hidden sm:block">
            <svg
              className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.92969 15.8792L15.8797 3.9292"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.1013 18.2791L12.3013 17.0791"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.793 15.5887L16.183 13.1987"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.60127 10.239L10.2413 3.599C12.3613 1.479 13.4213 1.469 15.5213 3.569L20.4313 8.479C22.5313 10.579 22.5213 11.639 20.4013 13.759L13.7613 20.399C11.6413 22.519 10.5813 22.529 8.48127 20.429L3.57127 15.519C1.47127 13.419 1.47127 12.369 3.60127 10.239Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 21.9985H22"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <div className="sm:ml-8">
            <h3 className=" text-slate-700 dark:text-slate-400 flex ">
              <span className="uppercase tracking-tight">PAYMENT METHOD</span>
              <svg
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2.5"
                stroke="currentColor"
                className="w-5 h-5 ml-3 text-slate-900"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            </h3>
            <div className="font-semibold mt-1 text-sm">
              <span className="">Payment Methods:</span>
              <span className="ml-3">Stripe, PayPal, or Bank Transfer</span>
            </div>
          </div>
          {/* <ButtonSecondary
            sizeClass="py-2 px-4 "
            fontSize="text-sm font-medium"
            className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
            onClick={onOpenActive}
          >
            Edit
          </ButtonSecondary> */}
        </div>

        <div
          className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-6 ${isActive ? "block" : "hidden"
            }`}
        >
          {/* ==================== */}
          <div>{renderDebitCredit()}</div>

          {/* ==================== */}
          <div>{renderPaypal()}</div>

          {/* ==================== */}
          <div>{renderInterNetBanking()}</div>

          {/* ==================== */}
          {/* <div>{renderWallet()}</div> */}

          {/* <div className="flex pt-6">
            <ButtonPrimary
              className="w-full max-w-[240px]"
              onClick={onCloseActive}
            >
              Checkout now
            </ButtonPrimary>
            <ButtonSecondary className="ml-3" onClick={onCloseActive}>
              Cancel
            </ButtonSecondary>
          </div> */}
        </div>
      </div>
    );
  };

  return renderPaymentMethod();
};

export default PaymentMethod;
