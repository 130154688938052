import axiosInstance from 'services/interceptor';
import { globalCurrentEventId } from 'utils/global';

interface OrderDetails {
  [key: string]: number;
}

interface CreateOrderResponse {
  success: boolean;
  orderId: string;
  message: string;
}

interface GetOrderResponse {
  success: boolean;
  order: any;
  orderItems: any;
  message: string;
}

interface GetOrdersResponse {
  success: boolean;
  order: any;
  orderItems: any;
  message: string;
}

export const createOrder = async (data: OrderDetails, eventId = 0): Promise<CreateOrderResponse> => {
  try {
    const response = await axiosInstance.post(`/customer/orders`, {
      orders: data,
      eventId: eventId
    });
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error.message;
  }
};

export const getLastOrder = async (staus = 'pending', event_id = 0): Promise<GetOrderResponse> => {
  try {
    const response = await axiosInstance.get(`/customer/orders/0?status=${staus}&event=${event_id}`);
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error.message;
  }
};

export const getOrders = async (staus = 'approved', event_id = 0): Promise<GetOrderResponse[]> => {
  try {
    const response = await axiosInstance.get(`/customer/orders?status=${staus}&event_id=${event_id}`);
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error.message;
  }
};

export const getBasketOrder = async (staus = 'approved', event_id = 0): Promise<any> => {
  try {
    const response = await axiosInstance.get(`/customer/orders?status=${staus}&event_id=${event_id}`);
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error.message;
  }
};

export const updateOrderItems = async (orderId: number, orderItems: any[], event_id=globalCurrentEventId()): Promise<any> => {
  try {
    const response = await axiosInstance.put(`/customer/orders/${orderId}/items`, { orderItems : orderItems, event_id: event_id });
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error.message;
  }
};