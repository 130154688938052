import axiosInstance from 'services/interceptor';

interface OrderDetails {
    [key: string]: number;
}

interface CreateOrderResponse {
    success: boolean;
    orderId: string;
    message: string;
}

interface GetOrderResponse {
    success: boolean;
    order: any;
    orderItems: any;
    message: string;
}

interface GetOrdersResponse {
    success: boolean;
    order: any;
    orderItems: any;
    message: string;
}

export const createOrder = async (data: OrderDetails): Promise<CreateOrderResponse> => {
    try {
        const response = await axiosInstance.post(`/customer/orders`, data);
        return response.data;
    } catch (error: any) {
        throw error.response ? error.response.data : error.message;
    }
};

export const getLastOrder = async (staus = 'pending'): Promise<GetOrderResponse> => {
    try {
        const response = await axiosInstance.get(`/customer/orders/0?status=${staus}`);
        return response.data;
    } catch (error: any) {
        throw error.response ? error.response.data : error.message;
    }
};

export const getOrders = async (staus = 'approved'): Promise<GetOrderResponse[]> => {
    try {
        const response = await axiosInstance.get(`/customer/orders?status=${staus}`);
        return response.data;
    } catch (error: any) {
        throw error.response ? error.response.data : error.message;
    }
};

export const updateOrderItems = async (orderId: number, orderItems: any[]): Promise<any[]> => {
    try {
        const response = await axiosInstance.put(`/customer/orders/${orderId}/items`, { orderItems: orderItems });
        return response.data;
    } catch (error: any) {
        throw error.response ? error.response.data : error.message;
    }
};

export const getEventDataById = async (eventId: number, type = 'booth-staff') => {
    try {
        const response = await axiosInstance.get(`/customer/events/${eventId}?type=${type}`);
        return response.data;
    } catch (error: any) {
        throw error.response ? error.response.data : error.message;
    }
}