import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import MenuBar from "shared/MenuBar/MenuBar";
import AvatarDropdown from "./AvatarDropdown";
import { Link } from "react-router-dom";

export interface MainNavProps {
  isTop: boolean;
}

const MainNav: FC<MainNavProps> = ({ isTop }) => {
  const user: any = localStorage.getItem('user');

  return (
    <div
      className={`nc-MainNav1 relative z-10 ${isTop ? "onTop " : "notOnTop backdrop-filter"
        }`}
    >
      <div className="container py-0 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
          <Logo />
          <Navigation />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          {/* <div className="hidden items-center xl:flex space-x-1">
            <SwitchDarkMode />
            <SearchDropdown />
            <div className="px-1" />
            <ButtonPrimary href="/login">Sign In</ButtonPrimary>
          </div> */}
          <div className="flex items-center lg:hidden">
            {/* <SwitchDarkMode /> */}
            <div className="px-1" />
            <MenuBar />
          </div>
          <span className="text-gray-500 font-medium">Exhibitor Services:</span>
          {
            user && user > 0 ?
              (<div className="flex-1 flex items-center justify-end ">
                <AvatarDropdown />
                <Link
                  to={"/dashboard"}
                  className="h-10 sm:h-12 px-2 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none flex items-center justify-center"
                >
                  <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icon-tabler-dashboard">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 13m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                      <path d="M13.45 11.55l2.05 -2.05" />
                      <path d="M6.4 20a9 9 0 1 1 11.2 0z" />
                    </svg>
                  </div>
                  <div className="ml-1">
                    <p className="text-sm font-medium ">{"Dashboard"}</p>
                  </div>
                </Link>
              </div>) : (
                <>
                  <div className="h-20 flex-shrink-0 flex items-center">
                    {/* <span className="text-gray-500">Exhibitor Services:</span> */}
                    <a
                      className="inline-flex items-center text-sm lg:text-[15px] font-medium text-slate-700 dark:text-slate-300 py-2.5 px-2 xl:px-3 rounded-full hover:text-slate-900 hover:bg-slate-100 dark:hover:bg-slate-800 dark:hover:text-slate-200 "
                      href={"/login"}
                    >
                      Sign In
                    </a>
                    <a
                      className="inline-flex items-center text-sm lg:text-[15px] font-medium text-slate-700 dark:text-slate-300 py-2 px-4 xl:px-3 rounded-full hover:text-slate-900 hover:bg-slate-100 dark:hover:bg-slate-800 dark:hover:text-slate-200 "
                      href={"/signup"}
                    >
                      Sign Up
                    </a>
                  </div>
                  {/* <ButtonSecondary href="/login" className="border-none" sizeClass="px-2 py-3">Sign In</ButtonSecondary>
                <ButtonSecondary href="/signup" className="border-none" sizeClass="px-2 py-3">Sign Up</ButtonSecondary> */}
                </>
              )
          }
        </div>
      </div>
    </div>
  );
};

export default MainNav;
