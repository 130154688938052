
const globalCurrentEventId = () => {
    const storedEventId = localStorage.getItem("selectedEventId");
    return storedEventId ? parseInt(storedEventId, 10) : 0;
};

const getCurrencySymbol = (currency: string) => {
    switch (currency.toLowerCase()) {
        case 'gbp':
            return '£';
        case 'usd':
            return '$';
        case 'eur':
            return '€';
        default:
            return '£'; // Defaulting to GBP if the currency is unrecognized
    }
}

export {
    globalCurrentEventId,
    getCurrencySymbol
}