import OrderProduct from 'components/OrderProduct';

const PageAdditionalServices = () => {
    return (
        <div className="container mx-auto p-6">
            <OrderProduct category_id={4} description='If you require any additional services not listed in this catalogue, please contact us and we will be happy to advise'/>
        </div>
    );
};

export default PageAdditionalServices;
