import React, { useState, useEffect } from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_LOGGED, NAVIGATION_LOGGED_AS_PLAYER, NAVIGATION_LOGGED_AS_EXHIBITOR } from "data/navigation";

function NavigationLogged() {
  const [isExhibitor, setIsExhibitor] = useState(false);
  useEffect(() => {
    const userRole = localStorage.getItem('user-role');
    if (userRole == 'ROLE_EXHIBITOR') {
      setIsExhibitor(true);
    } else {
      setIsExhibitor(false)
    }
  }, [])
  return (
    <ul className="nc-Navigation flex items-center">
      {isExhibitor ? NAVIGATION_LOGGED_AS_EXHIBITOR.map((item) => (
        <NavigationItem key={item.id} menuItem={item}/>
      )) :
        NAVIGATION_LOGGED.map((item) => (
          <NavigationItem key={item.id} menuItem={item} />
        ))
      }
    </ul>
  );
}

export default NavigationLogged;
