
import axiosInstance from 'services/interceptor';

export const saveSettings = async (data: any): Promise<any> => {
  try {
    const response = await axiosInstance.post(`/settings/`, data);
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error.message;
  }
}
  
