import axiosInstance from 'services/interceptor';

export const getAllExhibitors = async (): Promise<any> => {
  try {
    const response = await axiosInstance.get(`/customer/exhibitors`);
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error.message;
  }
};

export const getExhibitorById = async (userId: string): Promise<any> => {
  try {
    const response = await axiosInstance.get(`/customer/exhibitor/${userId}`);
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error.message;
  }
};

export const getExhibitorCompanyById = async (userId: string): Promise<any> => {
  try {
    const response = await axiosInstance.get(`/customer/exhibitor/${userId}/company`);
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error.message;
  }
};

export const updateExhibitorCompanyById = async (userId: string, data: any): Promise<any> => {
  try {
    const response = await axiosInstance.put(`/customer/exhibitor/${userId}/company`, data);
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error.message;
  }
};
