import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Prices from "components/Prices";
import { useNavigate } from "react-router-dom";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Helmet } from "react-helmet-async";
import { getBasketOrder } from "services/customer/order";
import { formatDate } from 'utils/others';
import { getCurrencySymbol, globalCurrentEventId } from "utils/global";

const PageOrderHistory = () => {
    const globalEventId = globalCurrentEventId();
    const navigate = useNavigate();
    const [orders, setOrders] = useState<any[]>([]); // Multiple orders
    const [orderItemsMap, setOrderItemsMap] = useState<Record<number, any[]>>({});

    // Fetch Orders on Component Mount
    useEffect(() => {
        const fetchData = async () => {
            const res = await getBasketOrder('approved', globalEventId);
            if (res && res.orders) {
                setOrders(res.orders);

                // Mapping orderItems to respective orders
                const itemsMap: Record<number, any[]> = {};
                res.orders.forEach((item: any) => {
                    const { order } = item;
                    itemsMap[order.id] = item.orderItems.map((orderItem: any) => ({
                        id: orderItem.product.id,
                        name: orderItem.product.name,
                        description: orderItem.product.description,
                        image: orderItem.product.image_url,
                        category: "Category 1",
                        tags: ["tag1", "tag2"],
                        price: parseFloat(orderItem.price),
                        quantity: orderItem.quantity,
                        status: "New in",
                    }));
                });

                setOrderItemsMap(itemsMap);
            }
        };

        fetchData();
    }, []);

    // Navigate to Invoice Page
    const handleInvoice = (order: any) => {
        // console.log('Navigating to invoice for order:', order);
        navigate(`/invoice?type=order&order_id=${order.id}`);
    };

    // Download Orders as Excel File
    const downloadOrder = () => {
        if (orders.length === 0) {
            console.error("No orders available.");
            return;
        }

        const headers = ["Order ID", "Product Name", "Description", "Category", "Tags", "Price", "Quantity", "Total Price", "Status"];
        const excelData = [headers];

        orders.forEach((order) => {
            const items = orderItemsMap[order.id] || [];
            items.forEach((item) => {
                excelData.push([
                    order.id,
                    item.name,
                    item.description,
                    item.category,
                    item.tags.join(", "),
                    item.price,
                    item.quantity,
                    (item.price * item.quantity).toFixed(2), // Total Price
                    item.status
                ]);
            });
        });

        const worksheet = XLSX.utils.aoa_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "OrderHistory");

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

        const dateTime = new Date().toISOString().replace(/[:.-]/g, '_').split('T').join('_');
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `Order_History_${dateTime}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Render Order Items
    const renderProductItem = (orderItem: any, index: number) => (
        <div key={index} className="flex py-4 sm:py-7 last:pb-0 first:pt-0">
            <div className="h-24 w-16 sm:w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
                <img
                    src={`${process.env.REACT_APP_BACKEND_URL}/${orderItem.image}`}
                    alt={orderItem.name}
                    className="h-full w-full object-cover object-center"
                />
            </div>
            <div className="ml-4 flex flex-1 flex-col">
                <div className="flex justify-between">
                    <div>
                        <h3 className="text-base font-medium line-clamp-1">{orderItem.name}</h3>
                        <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">{orderItem.description}</p>
                    </div>
                    <Prices className="mt-0.5 ml-2" price={parseFloat(orderItem.price)} />
                </div>
                <div className="flex flex-1 items-end justify-between text-sm">
                    <p className="text-gray-500 dark:text-slate-400 flex items-center">
                        Qty: <span className="ml-2">{orderItem.quantity}</span>
                    </p>
                </div>
            </div>
        </div>
    );

    // Render Orders
    const renderOrder = (order: any) => (
        <div className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0" key={order.id}>
            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-2 sm:p-4 bg-slate-50 dark:bg-slate-500/5">
                <div>
                    {/* <p className="text-lg font-semibold">#{order.id}</p> */}
                    <p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-1">
                        <span className="text-lg font-semibold">#{order.id}</span>
                        <span className="mx-2">·</span>
                        <span>{formatDate(order.updated_at || '2025-02-05')}</span>
                        <span className="mx-2">·</span>
                        <span className="text-secondary-500 mx-2">{`${getCurrencySymbol(order?.event?.currency)} ${order.total_price}`}</span>
                        <span className="mx-2">·</span>
                        <span className="text-primary-500">{order?.status?.toUpperCase()}</span>
                    </p>
                </div>
                <div className="mt-3 sm:mt-0">
                    <ButtonSecondary
                        sizeClass="py-2.5 px-4 sm:px-6"
                        fontSize="text-sm font-medium"
                        onClick={() => handleInvoice(order)}
                    >
                        Invoice
                    </ButtonSecondary>
                </div>
            </div>
            {/* <div className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">
                {orderItemsMap[order.id]?.map(renderProductItem)}
            </div> */}
        </div>
    );

    return (
        <div className="nc-CartPage">
            <main className="container py-4 lg:pb-28 lg:pt-8 ">
                <Helmet>
                    <title>Order Review || TorchEvents</title>
                </Helmet>
                <div className="space-y-1 sm:space-y-2">
                    <div className="flex justify-between items-center">
                        <h2 className="text-2xl sm:text-3xl font-semibold">Order History</h2>
                        <a href="/dashboard" className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition">
                            Back to Dashboard
                        </a>
                    </div>
                    {orders.length > 0 ? (
                        <>
                            {orders.map((item: any) => renderOrder(item.order))}
                            {/* <ButtonSecondary onClick={downloadOrder}>Download Order History</ButtonSecondary> */}
                        </>
                    ) : (
                        <p className="text-xl sm:text-2xl">No orders yet</p>
                    )}
                </div>
            </main>
        </div>
    );
};

export default PageOrderHistory;
