
import axiosInstance from 'services/interceptor';

export const getSripePaymentIntent = async (data: any): Promise<any> => {
    try {
        const response = await axiosInstance.post(`/customer/payments/stripe-payment-intent`, {
            amount: data.amount, // amount in cents
            currency: data.currency,
            meta: data.meta
        });
        return response.data;
    } catch (error: any) {
        throw error.response ? error.response.data : error.message;
    }
}

export const confirmSripePayment = async (data: any): Promise<any> => {
    try {
        const response = await axiosInstance.post(`/customer/payments/confirm-stripe-payment`, {
            success: true,
            status: 'succeeded',
            payment_id: data.clientSecret,
            amount: data.amount,
            currency: data.currency,
        });
        return response.data;
    } catch (error: any) {
        throw error.response ? error.response.data : error.message;
    }
}


export const createPayPalPayment = async (data: any): Promise<any> => {
    try {
        const response = await axiosInstance.post(`/customer/payments/paypal-payment`, {
            amount: data.amount, // amount in cents
            currency: data.currency,
            meta: data.meta
        });
        return response.data;
    } catch (error: any) {
        throw error.response ? error.response.data : error.message;
    }
}

export const confirmPaypalPayment = async (data: any) => {
    try {
        const response = await axiosInstance.post(`/customer/payments/confirm-paypal-payment`, {
            ...data,
            paymentId: data.paymentId,
        });
        return response.data;
    } catch (error: any) {
        throw error.response ? error.response.data : error.message;
    }
}

// Bank Transfer
export const createBankPayment = async (data: any): Promise<any> => {
    try {
        const response = await axiosInstance.post(`/customer/payments/bank-payment`, {
            amount: data.amount, // amount in cents
            currency: data.currency,
            meta: data.meta
        });
        return response.data;
    } catch (error: any) {
        throw error.response ? error.response.data : error.message;
    }
}
