import OrderProduct from 'components/OrderProduct';

const PageOrderAV = () => {
    return (
        <div className="container mx-auto p-6">
            <OrderProduct category_id={3} description='If you require other AV equipment or services not listed in this catalogue, please contact us and we will be happy to advise'/>
        </div>
    );
};

export default PageOrderAV;
