import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

export const MEGAMENU_TEMPLATES: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home Page",
    children: [
      { id: ncNanoId(), href: "/", name: "Home  1" },
      { id: ncNanoId(), href: "/home2", name: "Home  2", isNew: true },
      { id: ncNanoId(), href: "/", name: "Header  1" },
      { id: ncNanoId(), href: "/home2", name: "Header  2", isNew: true },
      { id: ncNanoId(), href: "/", name: "Coming Soon" },
    ],
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Shop Pages",
    children: [
      { id: ncNanoId(), href: "/page-collection", name: "Category Page 1" },
      { id: ncNanoId(), href: "/page-collection-2", name: "Category Page 2" },
      { id: ncNanoId(), href: "/product-detail", name: "Product Page 1" },
      { id: ncNanoId(), href: "/product-detail-2", name: "Product Page 2" },
      { id: ncNanoId(), href: "/cart", name: "Cart Page" },
      { id: ncNanoId(), href: "/checkout", name: "Checkout Page" },
    ],
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Other Pages",
    children: [
      { id: ncNanoId(), href: "/checkout", name: "Checkout Page" },
      { id: ncNanoId(), href: "/page-search", name: "Search Page" },
      { id: ncNanoId(), href: "/cart", name: "Cart Page" },
      { id: ncNanoId(), href: "/account", name: "Accout Page" },
      { id: ncNanoId(), href: "/account-my-order", name: "Order Page" },
      { id: ncNanoId(), href: "/subscription", name: "Subscription" },
    ],
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Blog Page",
    children: [
      { id: ncNanoId(), href: "/blog", name: "Blog Page" },
      { id: ncNanoId(), href: "/blog-single", name: "Blog Single" },
      { id: ncNanoId(), href: "/about", name: "About Page" },
      { id: ncNanoId(), href: "/contact", name: "Contact Page" },
      { id: ncNanoId(), href: "/login", name: "Login" },
      { id: ncNanoId(), href: "/signup", name: "Signup" },
    ],
  },
];

const OTHER_PAGE_CHILD: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home Demo 1",
  },
  {
    id: ncNanoId(),
    href: "/home2",
    name: "Home Demo 2",
  },
  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Category Pages",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/page-collection",
        name: "Category page 1",
      },
      {
        id: ncNanoId(),
        href: "/page-collection-2",
        name: "Category page 2",
      },
    ],
  },
  {
    id: ncNanoId(),
    href: "/product-detail",
    name: "Product Pages",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/product-detail",
        name: "Product detail 1",
      },
      {
        id: ncNanoId(),
        href: "/product-detail-2",
        name: "Product detail 2",
      },
    ],
  },
  {
    id: ncNanoId(),
    href: "/cart",
    name: "Cart Page",
  },
  {
    id: ncNanoId(),
    href: "/checkout",
    name: "Checkout Page",
  },
  {
    id: ncNanoId(),
    href: "/page-search",
    name: "Search Page",
  },
  {
    id: ncNanoId(),
    href: "/account",
    name: "Account Page",
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "Other Pages",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/about",
        name: "About",
      },
      {
        id: ncNanoId(),
        href: "/contact",
        name: "Contact us",
      },
      {
        id: ncNanoId(),
        href: "/login",
        name: "Login",
      },
      {
        id: ncNanoId(),
        href: "/signup",
        name: "Signup",
      },
      {
        id: ncNanoId(),
        href: "/subscription",
        name: "Subscription",
      },
    ],
  },
  {
    id: ncNanoId(),
    href: "/blog",
    name: "Blog Page",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/blog",
        name: "Blog Page",
      },
      {
        id: ncNanoId(),
        href: "/blog-single",
        name: "Blog Single",
      },
    ],
  },
];

export const NAVIGATION_DEMO_2: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Men",
  },
  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Women",
  },
  {
    id: ncNanoId(),
    href: "/page-collection-2",
    name: "Beauty",
  },

  {
    id: ncNanoId(),
    href: "/page-collection-2",
    name: "Sport",
  },
  {
    id: ncNanoId(),
    href: "/page-search",
    name: "Templates",
    type: "megaMenu",
    children: MEGAMENU_TEMPLATES,
  },
  {
    id: ncNanoId(),
    href: "/page-search",
    name: "Explore",
    type: "dropdown",
    children: OTHER_PAGE_CHILD,
  },
];

const INFORMATION_PAGE_CHILD: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/faq",
    name: "FAQ",
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "About",
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Contact Us",
  },
];

export const NAVIGATION: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/home",
    name: "Home",
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "About",
  },
  {
    id: ncNanoId(),
    href: "/faq",
    name: "FAQs",
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Contact Us",
  },
  // old router
  // {
  //   id: ncNanoId(),
  //   href: "/players",
  //   name: "Players",
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/coaches",
  //   name: "Coaches",
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/about",
  //   name: "Information",
  //   type: "dropdown",
  //   children: INFORMATION_PAGE_CHILD,
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/calendar",
  //   name: "Bookings",
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/page-search",
  //   name: "Templates",
  //   type: "megaMenu",
  //   children: MEGAMENU_TEMPLATES,
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/page-search",
  //   name: "Explore",
  //   type: "dropdown",
  //   children: OTHER_PAGE_CHILD,
  // },
];



export const NAVIGATION_LOGGED: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
  },
  {
    id: ncNanoId(),
    href: "/dashboard",
    name: "Dashboard",
  },
  {
    id: ncNanoId(),
    href: "/availability",
    name: "Availability",
  },
  {
    id: ncNanoId(),
    href: "/calendar",
    name: "Calendar",
  },
  {
    id: ncNanoId(),
    href: "/exercises",
    name: "Exercises",
  },
];

export const NAVIGATION_LOGGED_AS_PLAYER: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
  },
  {
    id: ncNanoId(),
    href: "/player-dashboard",
    name: "Dashboard",
  },
  {
    id: ncNanoId(),
    href: "/player-calendar",
    name: "Calendar",
  },
  {
    id: ncNanoId(),
    href: "/members",
    name: "Players",
  },
  {
    id: ncNanoId(),
    href: "/player-coaches",
    name: "Coaches",
  },

];


export const NAVIGATION_MOBILE: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
  },
  {
    id: ncNanoId(),
    href: "/players",
    name: "Players",
  },
  {
    id: ncNanoId(),
    href: "/coaches",
    name: "Coaches",
  },

  {
    id: ncNanoId(),
    href: "/about",
    name: "About",
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Contact",
    type: "megaMenu",
    // children: MEGAMENU_TEMPLATES,
  },

];

export const LOGGED_ROUTES = [
  'calendar',
  'dashboard',
  'availability',
  'exercises',
  'members',
  // 'coaches',
  'group',
  'player-coaches',
  'player-dashboard',
  'player-calendar',
  'account',
  'exhibitor-information',
  'register-booth-staff',
  'register-exhibitor-delegates',
  'order-furniture',
  'order-electrics',
  'order-audio-visual',
  'additional-services',
  'review-order',
  'order-basket',
  'account-billing',
  'settings',
  'checkout',
  'account-my-order',
  'order-review',
]

export const HOME_NAV_MENUS = [
  {
    href: "/",
    name: "Home",
  },
  {
    href: "/events",
    name: "Events",
  },
  {
    "href": "/#about",
    "name": "About"
  }, {
    "href": "/#faq",
    "name": "FAQs"
  }, {
    "href": "/#contact",
    "name": "Contact Us"
  }
]

export const NAVIGATION_LOGGED_AS_EXHIBITOR: NavItemType[] = [
  // {
  //   id: ncNanoId(),
  //   href: "/",
  //   name: "Home",
  // },
  {
    id: ncNanoId(),
    href: "/dashboard",
    name: "Dashboard",
  },
  {
    id: ncNanoId(),
    href: "/exhibitor-information",
    name: "Exhibiting", //  Management
    type: 'dropdown',
    children: [
      { id: ncNanoId(), href: "/exhibitor-information", name: "Exhibitor Information"},
      { id: ncNanoId(), href: "/register-booth-staff", name: "Booth Staff" },
      { id: ncNanoId(), href: "/register-exhibitor-delegates", name: "Exhibitor Delegates" },
    ],
  },
  {
    id: ncNanoId(),
    href: "/order-furniture",
    name: "Order Services",
    type: 'dropdown',
    children: [
      { id: ncNanoId(), href: "/order-furniture", name: "Order Furniture" },
      { id: ncNanoId(), href: "/order-electrics", name: "Order Electrics" },
      { id: ncNanoId(), href: "/order-audio-visual", name: "Order Audio Visual" },
      { id: ncNanoId(), href: "/additional-services", name: "Additional Services" },
      { id: ncNanoId(), href: "/order-review", name: "Order History" }, // review-order
      { id: ncNanoId(), href: "/order-basket", name: "Basket" },
    ],
  },
];